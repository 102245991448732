import { Component, OnInit } from '@angular/core';
import {DeudaFileService, HttpRequestProgress} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AsyncTask, AsyncTaskService} from "adtk/async-task";
import {ActionButton, ControlPanelService} from "adtk/control-panel";

@Component({
  selector: 'app-deuda-create',
  templateUrl: './deuda-create.component.html',
  styleUrls: ['./deuda-create.component.scss']
})
export class DeudaCreateComponent implements OnInit {

  private garanteIdMap = new Map();
  garantes: string[] = ['Adeslas', 'Asisa', 'Mapfre', 'DKV'];
  allGarantes: string[] = [];

  file: File;
  task_id = '';
  upload_progress: number;
  status: string = "init";

  private actionIniciar: ActionButton = { name: 'Iniciar', click: () => this.onIniciar(), enabled:true, disabled:true, color: "primary" };
  private actionCancel: ActionButton = { name: 'Cancelar', click: () => this.onCancelar(), enabled:true, color: "accent" };

  constructor(private deudaFileService: DeudaFileService, private controlPanelService: ControlPanelService, public asyncTaskService: AsyncTaskService, public router: Router, public r:ActivatedRoute, private _location: Location) {
      this.controlPanelService.title = "Importar deuda";
      this.controlPanelService.subtitle = "Inicia el proceso de importación de un fichero de deuda. La carga de un nuevo fichero reestablece las líneas de conciliaciones anteriores no validadas.";
      this.controlPanelService.actionButtons = [this.actionIniciar, this.actionCancel];
      this.controlPanelService.secondaryActionButtons = [];
      this.controlPanelService.statusbarButtons = [];
      this.garanteIdMap.set("Asisa", 1);
      this.garanteIdMap.set("Adeslas", 2);
      this.garanteIdMap.set("Mapfre", 3);
      this.garanteIdMap.set("DKV", 4);
}

  ngOnInit() {
  }

  //TODO: crear componente y reutilizar en conciliacion-create
  getGarantesId(): number[] {
    let garantes_id:number[] = [];
    for (let entry of this.garantes) {
      garantes_id.push(this.garanteIdMap.get(entry))
    }
    return garantes_id;
  }

  onChangeFile(file: File): void {
    this.file = file;
    if (file) {
      this.actionIniciar.disabled = false;
    } else {
      this.actionIniciar.disabled = true;
    }
  }


  onIniciar() {
    if (this.file) {
      this.status = "uploading";
      this.actionIniciar.disabled = true;
      this.deudaFileService.createFromFileAndGarantes(this.file, this.getGarantesId())
        .subscribe(ret => this.setTask(ret));
    }
  }

  onCancelar() {
      this._location.back();
  }

  setTask(response: HttpRequestProgress) {
    switch (response.status) {
      case "done":
        this.status = "processing";
        const message = response.message;
        this.task_id = message.task_id;
        if (!this.task_id) {
          return;
        }
        this.actionCancel.disabled = true;
//        this.asyncTaskService
//          .addTaskId(this.task_id)
//          .subscribe(asyncResult => this.setTaskStatus(asyncResult));
        break;
      case "progress":
        this.upload_progress = response.percent;
        break;
    };
  }

  setTaskStatus(asyncResult: AsyncTask) {
    if (!asyncResult || asyncResult.status == 'SUCCESS') {
      this.status = "success";
      this._location.back();
    }
  }


}
