import { Component } from '@angular/core';
import {ValidacionFile} from "app/modules/conciliacion/_models/liquidacion";
import {
  ValidacionFileDataSourceService
} from "app/modules/conciliacion/core/LiquidacionesDataSource";
import {ValidacionFileService} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {EntitiesComponent} from "adtk/remote-entity";
import {ActionButton, ControlPanelService} from "adtk/control-panel";
import {ConfirmationDialogComponent} from "adtk/confirmation-dialog";

@Component({
  selector: 'app-validacion-file-list',
  templateUrl: './validacion-file-list.component.html',
  providers: [ValidacionFileDataSourceService],
  styleUrls: ['./validacion-file-list.component.scss']
})
export class ValidacionFileListComponent extends EntitiesComponent<ValidacionFile>  {

    private actionCrear: ActionButton = { name: 'Importar', click: () => this.onImportar(), enabled:true, color: "primary" };
    private actionEliminar: ActionButton = { name: 'Eliminar', click: () => this.onEliminar(), enabled:false, color: "accent" };

    constructor(public dialog: MatDialog, public override dataSource: ValidacionFileDataSourceService,
                private validacionFileService: ValidacionFileService,
                private controlPanelService: ControlPanelService,
                private router: Router, private r:ActivatedRoute) {
      super(dataSource, validacionFileService);
      this.displayedColumns = ['select','download-file', 'descripcion', 'file_date'];
      this.controlPanelService.title = "Validación de conciliaciones";
      this.controlPanelService.subtitle = "Las lineas validadas no se utilizarán en conciliaciones futuras";
      this.controlPanelService.actionButtons = [this.actionCrear, this.actionEliminar];
      this.controlPanelService.secondaryActionButtons = [];
      this.controlPanelService.statusbarButtons = [];
      this.selection.changed.subscribe( _ => {
        this.actionEliminar.enabled = this.selection.hasValue()
      });
    }

  override ngOnInit() {
      super.ngOnInit();
      this.matSort.sort({id:'file_date', start:'desc', disableClear:true})
    }

  onImportar() {
      this.router.navigate(["nueva"], { relativeTo: this.r });
  }

  onEliminar() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
     // width: '350px',
      data: "Desea eliminar las validaciones?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.validacionFileService.deleteAll(this.selection.selected).subscribe(ret =>
        {
          console.log(`Tarea eliminación lanzada ${ret.task_id}.`);
          this.loadData();
        })
      }
    });
  }
  download(file_id: string) {
    this.validacionFileService.downloadFile(file_id);
  }

  onRowClicked(row: any) {
    console.log('Row clicked: ', row);
      this.router.navigate(["editar",  row.id], { relativeTo: this.r });
  }


}
