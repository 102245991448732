import {Component, OnInit} from '@angular/core';
import {CloudFileBase} from "adtk/cloud-file";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-cloudfile',
  templateUrl: './cloudfile.component.html',
  styleUrls: ['./cloudfile.component.scss']
})
export class CloudfileComponent implements OnInit {

  constructor(private router: Router, private r:ActivatedRoute, public _location: Location) { }

  ngOnInit(): void {
  }

  openFile(cloudFile: CloudFileBase) {
    this.router.navigate(['/conciliacion/liquidacion'], { queryParams: {file_id: cloudFile.id}, relativeTo: this.r });
  }

}
