


    <div class="spinner-container" *ngIf="dataSource.loading$ | async ">
        <mat-spinner></mat-spinner>
    </div>

  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

         <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell class="min-width" *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>


    <!-- Checkbox Column -->
  <ng-container matColumnDef="download-file">
    <th mat-header-cell class="min-width" *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button matTooltip="Descargar fichero" (click)="download(element.file_id)"><mat-icon inline="true">cloud_download</mat-icon></button>
    </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell class="right" *matHeaderCellDef> Id </th>
    <td mat-cell class="right" *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="descripcion">
    <th mat-header-cell *matHeaderCellDef> Nombre </th>
    <td mat-cell *matCellDef="let element"> {{element.file_name}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="file_date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="file_date"> Fecha </th>
    <td mat-cell *matCellDef="let element"> {{element.file_date | date:'dd/MM/yyyy HH:mm'}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"></tr>

</table>

    <!--<mat-paginator [length]="course?.lessonsCount" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 40]" showFirstLastButtons></mat-paginator>

