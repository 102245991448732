import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import {ConciliacionComponent} from "app/modules/conciliacion/conciliacion.component";
import {LiquidacionListComponent} from "app/modules/conciliacion/liquidacion/liquidacion-list/liquidacion-list.component";
import {LiquidacionFileListComponent} from "app/modules/conciliacion/liquidacion/liquidacion-file-list/liquidacion-file-list.component";
import {ConciliacionFileListComponent} from "app/modules/conciliacion/conciliacion/conciliacion-file-list/conciliacion-file-list.component";
import {ConciliacionCreateComponent} from "app/modules/conciliacion/conciliacion/conciliacion-create/conciliacion-create.component";
import {FacturacionFileListComponent} from "app/modules/conciliacion/facturacion/facturacion-file-list/facturacion-file-list.component";
import {DeudaFileListComponent} from "app/modules/conciliacion/deuda/deuda-file-list/deuda-file-list.component";
import {ValidacionFileListComponent} from "app/modules/conciliacion/validacion/validacion-file-list/validacion-file-list.component";
import {ValidacionCreateComponent} from "app/modules/conciliacion/validacion/validacion-create/validacion-create.component";
import {LiquidacionCreateComponent} from "app/modules/conciliacion/liquidacion/liquidacion-create/liquidacion-create.component";
import {FacturacionCreateComponent} from "app/modules/conciliacion/facturacion/facturacion-create/facturacion-create.component";
import {DeudaCreateComponent} from "app/modules/conciliacion/deuda/deuda-create/deuda-create.component";
import {ValidacionSheetComponent} from "app/modules/conciliacion/validacion/validacion-sheet/validacion-sheet.component";
import {LcolleniAuthGuard} from "../../auth/keycloak/keycloak.authguard";


const conciliacionRoutes: Routes = [
  {
    path: 'conciliacion',
    component: ConciliacionComponent,
    canActivate: [LcolleniAuthGuard],
    data: {roles: ["conciliacion-vithasmalaga",]},
    children: [
      { path: 'validacion', component: ValidacionFileListComponent },
      { path: 'validacion/nueva', component: ValidacionCreateComponent },
      { path: 'validacion/editar/:validacion_id', component: ValidacionSheetComponent },
      { path: 'liquidacionfile', component: LiquidacionFileListComponent },
      { path: 'liquidacionfile/nueva', component: LiquidacionCreateComponent },
      { path: 'liquidacion', component: LiquidacionListComponent },
      { path: 'facturacion', component: FacturacionFileListComponent },
      { path: 'facturacion/nueva', component: FacturacionCreateComponent },
      { path: 'deuda', component: DeudaFileListComponent },
      { path: 'deuda/nueva', component: DeudaCreateComponent },
      { path: 'conciliacion', component: ConciliacionFileListComponent },
      { path: 'conciliacion/nueva', component: ConciliacionCreateComponent },
      { path: '',  redirectTo: 'conciliacion', pathMatch: 'prefix' },
    ]
  }
  ];

@NgModule({
  imports: [RouterModule.forChild(conciliacionRoutes)],
  exports: [RouterModule]
})
export class ConciliacionRoutingModule { }
