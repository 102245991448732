import {Component, OnInit} from '@angular/core';
import {FacturacionFileService, HttpRequestProgress} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AsyncTask, AsyncTaskService} from "adtk/async-task";
import {ActionButton, ControlPanelService} from "adtk/control-panel";

@Component({
  selector: 'app-facturacion-create',
  templateUrl: './facturacion-create.component.html',
  styleUrls: ['./facturacion-create.component.scss']
})
export class FacturacionCreateComponent implements OnInit {


  file: File;
  task_id = '';
  upload_progress: number;
  status: string = "init";

  private actionIniciar: ActionButton = { name: 'Iniciar', click: () => this.onIniciar(), enabled:true, disabled:true, color: "primary" };
  private actionCancel: ActionButton = { name: 'Cancelar', click: () => this.onCancelar(), enabled:true, color: "accent" };

  constructor(private facturacionFileService: FacturacionFileService, private controlPanelService: ControlPanelService, public asyncTaskService: AsyncTaskService, public router: Router, public r:ActivatedRoute, private _location: Location) {
      this.controlPanelService.title = "Importar nuevo listado de facturación";
      this.controlPanelService.subtitle = "Inicia el proceso de importación de listados de facturación (recapitulativa, SAP, xHIS)";
      this.controlPanelService.actionButtons = [this.actionIniciar, this.actionCancel];
      this.controlPanelService.secondaryActionButtons = [];
      this.controlPanelService.statusbarButtons = [];
  }

  ngOnInit() {
  }

  onChangeFile(file: File): void {
    this.file = file;
    if (file) {
      this.actionIniciar.disabled = false;
    } else {
      this.actionIniciar.disabled = true;
    }
  }

  onIniciar() {
    if (this.file) {
      this.status = "uploading";
      this.facturacionFileService.createFromFile(this.file)
        .subscribe(ret => this.setTask(ret));
    }
  }

  onCancelar() {
      this._location.back();
  }

  setTask(response: HttpRequestProgress) {
    switch (response.status) {
      case "done":
        this.status = "processing";
        const message = response.message;
        this.task_id = message.task_id;
        if (!this.task_id) {
          return;
        }
        this.actionIniciar.disabled = true;
        this.actionCancel.disabled = true;
//        this.asyncTaskService
//          .addTaskId(this.task_id)
//          .subscribe(asyncResult => this.setTaskStatus(asyncResult));
        break;
      case "progress":
        this.upload_progress = response.percent;
        break;
    };
  }

  setTaskStatus(asyncResult: AsyncTask) {
    if (!asyncResult || asyncResult.status == 'SUCCESS') {
      this.status = "success";
      this._location.back();
    }
  }



}
