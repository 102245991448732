import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  UnidadNegocioDataSource
} from "app/modules/conciliacion/core/LiquidacionesDataSource";
import {UnidadNegocio} from "app/modules/conciliacion/_models/liquidacion";

@Component({
  selector: 'app-unidad-negocio-select',
  templateUrl: './unidad-negocio-select.component.html',
  styleUrls: ['./unidad-negocio-select.component.scss']
})
export class UnidadNegocioSelectComponent implements OnInit {

  unidadesNegocio: UnidadNegocio[];

  unidadNegocioValue: UnidadNegocio;
  @Output() unidadNegocioChanged = new EventEmitter();

  constructor(public dataSource: UnidadNegocioDataSource) {
  }

  ngOnInit() {
    this.dataSource.loadData();
    this.getUnidadesNegocio();
  }

  compareFn(c1: UnidadNegocio, c2: UnidadNegocio): boolean {
      return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  @Input()
  get unidadNegocio() {
    return this.unidadNegocioValue;
  }

  set unidadNegocio(val) {
    this.unidadNegocioValue = val;
    this.unidadNegocioChanged.emit(this.unidadNegocioValue);
  }

  getUnidadesNegocio(): void {
    this.dataSource.asObservable().subscribe(unidadesNegocio => this.unidadesNegocio = unidadesNegocio);
  }

}
