<ng-container *ngIf="!task_id">
<div class="conciliacion-form-container">
  <form class="conciliacion-form" [formGroup]="conciliacionForm" (ngSubmit)="onSubmit()">
     <span class="options-list-section">
    <ul>
      <li>
    <mat-checkbox class="example-margin" formControlName="validar_cobro">Validar cobro</mat-checkbox>
      </li>
      <li>
    <mat-checkbox class="example-margin" formControlName="delete_no_validado">Borrar no validados</mat-checkbox>
      </li>
    </ul>
  </span>
    <p>Seleccione las compañías a conciliar:</p>
    <app-select-chips formControlName="garantes" [allElements]="allGarantes" placeholder="Compañías..."></app-select-chips>
    <p>Seleccione grupos de conciliación:</p>
    <app-select-chips formControlName="conciliacion_grupo" [allElements]="allConciliacionGrupo" placeholder="Grupo de conciliación..."></app-select-chips>
    <p>Seleccione Unidades de Negocio para la conciliación:</p>
    <app-select-chips formControlName="unidad_negocio"  [allElements]="allUnidadesNegocio" placeholder="Unidades de Negocio..."></app-select-chips>
    <button mat-raised-button type="submit" [disabled]="!conciliacionForm.valid">Confirmar</button>
  </form>
</div>
</ng-container>

<ng-container *ngIf="task_id">
  <mat-progress-spinner
    class="example-margin"
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
  <p>Procesando datos... </p>
</ng-container>



