import { Component } from '@angular/core';
import {
  ConciliacionFileDataSourceService
} from "app/modules/conciliacion/core/LiquidacionesDataSource";
import {ConciliacionFileService} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ConciliacionFile} from "app/modules/conciliacion/_models/liquidacion";
import {EntitiesComponent} from "adtk/remote-entity";
import {ActionButton, ControlPanelService} from "adtk/control-panel";

@Component({
  selector: 'app-conciliacion-file-list',
  templateUrl: './conciliacion-file-list.component.html',
  styleUrls: ['./conciliacion-file-list.component.scss'],
  providers: [ConciliacionFileDataSourceService]
})
export class ConciliacionFileListComponent extends EntitiesComponent<ConciliacionFile>  {

  private actionNueva: ActionButton = { name: 'Nueva', click: () => this.onNueva(), enabled:true, color: "primary" };

  constructor(public override dataSource: ConciliacionFileDataSourceService,
                private conciliacionFileService: ConciliacionFileService,
                private controlPanelService: ControlPanelService,
                private router: Router, private r:ActivatedRoute) {
    super(dataSource, conciliacionFileService);
    this.displayedColumns = ['select',  'descripcion', 'file_date'];
    this.controlPanelService.title = "Conciliaciones";
    this.controlPanelService.subtitle = "Descarga de ficheros de resultados de conciliación.";
    this.controlPanelService.actionButtons = [this.actionNueva];
    this.controlPanelService.secondaryActionButtons = [];
    this.controlPanelService.statusbarButtons = [];

  }

  override ngOnInit() {
      super.ngOnInit();
      this.matSort.sort({id:'file_date', start:'desc', disableClear:true})
  }

  onNueva() {
      this.router.navigate(["nueva"], { relativeTo: this.r });
  }

  download(row: any) {
    this.conciliacionFileService.downloadFile(row.file_id);
  }

}
