import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {LiquidacionFile} from "app/modules/conciliacion/_models/liquidacion";
import { LiquidacionFileDataSourceService } from "app/modules/conciliacion/core/LiquidacionesDataSource";
import {LiquidacionFileService} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {Location} from "@angular/common";
import {EntitiesComponent} from "adtk/remote-entity";
import {ActionButton, ControlPanelService} from "adtk/control-panel";
import {ConfirmationDialogComponent} from "adtk/confirmation-dialog";
import {FileListComponent} from "adtk/cloud-file";
import {MatCellDef, MatColumnDef, MatHeaderCellDef} from "@angular/material/table";

@Component({
  selector: 'app-liquidacion-file-list',
  templateUrl: './liquidacion-file-list.component.html',
  providers: [ LiquidacionFileDataSourceService ],
  styleUrls: ['./liquidacion-file-list.component.scss']
})
export class LiquidacionFileListComponent extends EntitiesComponent<LiquidacionFile> implements AfterViewInit  {



    public actionCrear: ActionButton = { name: 'Importar', click: () => this.onImportar(), enabled:true, color: "primary" };
    public actionEliminar: ActionButton = { name: 'Eliminar', click: () => this.onEliminar(), enabled:false, color: "accent" };
    public actionProcesar: ActionButton = { name: 'Procesar', click: () => this.onProcesar(), enabled:false, color: "accent" };

    @ViewChild('someInput') appFileList: FileListComponent;
    @ViewChild(MatColumnDef , { static: true }) columnDef: MatColumnDef;
    @ViewChild(MatCellDef, {static: true}) cell: MatCellDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCell: MatHeaderCellDef;

    constructor(public dialog: MatDialog,
                public override dataSource: LiquidacionFileDataSourceService,
                private liquidacionFileService: LiquidacionFileService,
                private controlPanelService: ControlPanelService,
                private router: Router, private r:ActivatedRoute, public _location: Location) {
      super(dataSource, liquidacionFileService);
      this.displayedColumns = ['select', 'download-file', 'download-pdf', 'garante','descripcion', 'file_date'];
      this.controlPanelService.title = "Ficheros";
      this.controlPanelService.subtitle = "Ficheros correspondientes a liquidaciones o facturación";
      this.controlPanelService.actionButtons = [this.actionCrear, this.actionEliminar, this.actionProcesar];
      this.controlPanelService.secondaryActionButtons = [];
      this.controlPanelService.statusbarButtons = [];
      this.selection.changed.subscribe( _ => {
        this.actionEliminar.enabled = this.selection.hasValue()
        this.actionProcesar.enabled = this.selection.hasValue()
      });
    }

  override ngOnInit() {
      super.ngOnInit();
      this.matSort.sort({id:'file_date', start:'desc', disableClear:true})
    }

  override ngAfterViewInit() {
      super.ngAfterViewInit();
      this.controlPanelService.title="Liquidaciones";
      this.controlPanelService.subtitle = "Ficheros correspondientes a liquidaciones o facturación";

      // Provide the cell and headerCell directly to the table with the static `ViewChild` query,
      // since the columnDef will not pick up its content by the time the table finishes checking
      // its content and initializing the rows.
      this.columnDef.cell = this.cell;
      this.columnDef.headerCell = this.headerCell;
      this.appFileList.table.addColumnDef(this.columnDef);
      this.appFileList.displayedColumns.push('file-size');

      this.controlPanelService.actionButtons = [this.actionCrear, this.appFileList.actionEliminar, this.actionProcesar];
  }

  onImportar() {
      this.router.navigate(["nueva"], { relativeTo: this.r });
  }

  onEliminar() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
     // width: '350px',
      data: "Desea eliminar las liquidaciones?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.liquidacionFileService.deleteAll(this.selection.selected).subscribe(ret =>
        {
          console.log(`Tarea eliminación lanzada ${ret.task_id}.`);
          this.loadData();
        })
      }
    });
  }

  onProcesar() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
     // width: '350px',
      data: "Desea procesar de nuevo las liquidaciones?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.liquidacionFileService.processAll(this.selection.selected).subscribe(ret =>
        {
          console.log(`Tarea procesamiento lanzada ${ret.task_id}.`);
          this.loadData();
        })
      }
    });
  }

  download(file_id: string) {
    this.liquidacionFileService.downloadFile(file_id).subscribe();
  }



}
