import { MatPaginatorIntl } from '@angular/material/paginator';
import {MatDatepickerIntl} from "@angular/material/datepicker";
import {Injectable} from "@angular/core";

const spanishRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 de ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} de ${length}`;
}


export function getSpanishPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Elementos por página:';
  paginatorIntl.nextPageLabel = 'Página siguiente';
  paginatorIntl.previousPageLabel = 'Página anterior';
  paginatorIntl.getRangeLabel = spanishRangeLabel;

  return paginatorIntl;
}


/** Datepicker data that requires internationalization. */
@Injectable()
export class DatepickerEsp extends MatDatepickerIntl {
  constructor() {
    super();
  }

  /** A label for the calendar popup (used by screen readers). */
  override calendarLabel = 'Calendario';

  /** A label for the button used to open the calendar popup (used by screen readers). */
  override openCalendarLabel = 'Abrir calendario';

  /** A label for the previous month button (used by screen readers). */
  override prevMonthLabel = 'Mes anterior';

  /** A label for the next month button (used by screen readers). */
  override nextMonthLabel = 'Mes siguiente';

  /** A label for the previous year button (used by screen readers). */
  override prevYearLabel = 'Año anterior';

  /** A label for the next year button (used by screen readers). */
  override nextYearLabel = 'Año siguiente';

  /** A label for the 'AM' button (used by screen readers). */
  setToAMLabel = 'Fijar hora a AM';

  /** A label for the 'PM' button (used by screen readers). */
  setToPMLabel = 'Fijar hora a PM';

  /** A label for the 'switch to minute view' button (used by screen readers). */
  switchToMinuteViewLabel = 'Cambiar a vista de minutos';

  /** A label for the 'switch to hour view' button (used by screen readers). */
  switchToHourViewLabel = 'Cambiar a vista de horas';

  /** A label for the 'switch to month view' button (used by screen readers). */
  override switchToMonthViewLabel = 'Cambiar a vista del mes';

  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToYearViewLabel = 'Cambiar a vista de año';

  /** A label for the 'switch to years view' button (used by screen readers). */
  switchToYearsViewLabel = 'Cambiar a vista de años';

  /** Text for the 'submit' button. */
  buttonSubmitText = 'Ok';

  /** A label for the 'submit' button (used by screen readers). */
  buttonSubmitLabel = 'Elegir la fecha seleccionada';

  /** Text for the 'cancel' button. */
  buttonCancelText = 'Cancelar';

  /** A label for the 'cancel' button (used by screen readers). */
  buttonCancelLabel = 'Cancelar la selección de fecha';
}
