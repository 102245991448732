import { Component, OnInit } from '@angular/core';
import {HttpRequestProgress, LiquidacionFileService} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AsyncTask, AsyncTaskService} from "adtk/async-task";
import {ActionButton, ControlPanelService} from "adtk/control-panel";

@Component({
  selector: 'app-liquidacion-create',
  templateUrl: './liquidacion-create.component.html',
  styleUrls: ['./liquidacion-create.component.scss']
})
export class LiquidacionCreateComponent implements OnInit {

  file: File;
  file_id = 0;
  task_id = '';
  upload_progress: number;
  status: string = "init";

  private actionIniciar: ActionButton = { name: 'Iniciar', click: () => this.onIniciar(), enabled:true, disabled:true, color: "primary" };
  private actionCancel: ActionButton = { name: 'Cancelar', click: () => this.onCancelar(), enabled:true, color: "accent" };

  constructor(private liquidacionFileService: LiquidacionFileService, private controlPanelService: ControlPanelService, private asyncTaskService: AsyncTaskService, private router: Router, public r:ActivatedRoute, private _location: Location) {
      this.controlPanelService.title = "Importar fichero";
      this.controlPanelService.subtitle = "Inicia el proceso de importación de ficheros para conciliar";
      this.controlPanelService.actionButtons = [this.actionIniciar, this.actionCancel];
      this.controlPanelService.secondaryActionButtons = [];
      this.controlPanelService.statusbarButtons = [];
  }

  ngOnInit() {
  }


  onChangeFile(file: File): void {
    this.file = file;
    if (file) {
      this.actionIniciar.disabled = false;
    } else {
      this.actionIniciar.disabled = true;
    }
  }

  onIniciar() {
    if (this.file) {
      this.status = "uploading";
      this.liquidacionFileService.createFromFile(this.file)
        .subscribe(ret => this.setTask(ret));
    }
  }

  onCancelar() {
      this._location.back();
  }

  setTask(response: HttpRequestProgress) {
    switch (response.status) {
      case "done":
        this.status = "processing";
        const message = response.message;
        this.task_id = message.task_id;
        this.file_id = message.id;
        if (!this.task_id) {
          return;
        }
        this.actionIniciar.disabled = true;
        this.actionCancel.disabled = true;
        this.asyncTaskService.getAsyncTaskObserver(this.task_id)
                .subscribe(asyncTask => this.setTaskStatus(asyncTask));
        break;
      case "progress":
        this.upload_progress = response.percent;
        break;
    };
  }

  setTaskStatus(asyncTask: AsyncTask) {
    if (!asyncTask || asyncTask.status === 'SUCCESS') {
      this.status = "success";
      this.router.navigate(['/conciliacion/liquidacion'], {
      queryParams: { 'file_id': this.file_id }
    });
    }
  }


}
