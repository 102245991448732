import { Component } from '@angular/core';
import {Liquidacion, UnidadNegocio} from "app/modules/conciliacion/_models/liquidacion";
import {
  LiquidacionDataSourceService
} from "app/modules/conciliacion/core/LiquidacionesDataSource";
import {LiquidacionService} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {EntitiesComponent} from "adtk/remote-entity";
import {ActionButton, ControlPanelService} from "adtk/control-panel";

@Component({
  selector: 'app-liquidacion-list',
  templateUrl: './liquidacion-list.component.html',
  providers: [ LiquidacionDataSourceService ],
  styleUrls: ['./liquidacion-list.component.scss']
})
export class LiquidacionListComponent extends EntitiesComponent<Liquidacion> {

    file_id: Observable<string>;
    private actionCrear: ActionButton = { name: 'Crear', click: () => this.onCrear(), enabled:true, color: "primary" };
    private actionEditar: ActionButton = { name: 'Editar', click: () => this.onEditar(), enabled:true, color: "primary" };

    constructor(public override dataSource: LiquidacionDataSourceService, private liquidacionService: LiquidacionService, private controlPanelService: ControlPanelService, private router: Router, private r:ActivatedRoute) {
      super(dataSource, liquidacionService);
      this.liquidacionService.container='conciliaciones';
      this.displayedColumns = ['select',  'unidad_negocio', 'garante', 'descripcion', 'fecha_fin', 'importe'];
      this.controlPanelService.title = "Liquidaciones";
      this.controlPanelService.subtitle = "Liquidaciones mensuales realizadas por las compañías";
      this.controlPanelService.actionButtons = [this.actionEditar, this.actionCrear];
      this.controlPanelService.secondaryActionButtons = [];
      this.controlPanelService.statusbarButtons = [];
    }

  override ngOnInit() {

      this.file_id = this.r
      .queryParamMap
      .pipe(map(params => params.get('file_id') || 'None'));
      this.file_id.subscribe(value => {console.log(value);
           if (value!='None') {this.filter = 'liquidacion_file_id==' + value};
          super.ngOnInit();
      });

      this.matSort.sort({id:'fecha_fin', start:'desc', disableClear:true})
  }

  updateUnidadNegocio(element: Liquidacion, unidadNegocio: UnidadNegocio) {
      if (!element
          ||(!element.unidad_negocio && !unidadNegocio)
          || (element.unidad_negocio && unidadNegocio &&  element.unidad_negocio.id == unidadNegocio.id))
        return;

      this.liquidacionService
        .updateUnidadNegocio(String(element.id), String(unidadNegocio.id))
        .subscribe(_ => element.unidad_negocio = unidadNegocio);

  }

  onCrear() {
      this.router.navigate(["nueva"], { relativeTo: this.r });
  }

  onEditar() {
      this.router.navigate(["nueva"], { relativeTo: this.r });
  }

}
