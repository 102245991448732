
export class CloudFileBase {
  id: number;
  file_name: string;
  file_date: Date;
  file_id: string;
  container: string;
}

export class Garante{
  id: number;
  nombre: string;
}

export class ConciliacionFile extends CloudFileBase {
}

export class FacturacionFile extends CloudFileBase {
}

export class DeudaFileGarante {
  id: number;
  garante: Garante;
}

export class DeudaFile extends CloudFileBase {
  deuda_garantes: DeudaFileGarante[];
}

export class ValidacionFile extends CloudFileBase {
}

export class LiquidacionFile extends CloudFileBase {
  garante: Garante;
  pdf_cloud_file: CloudFileBase;
}

export class CentroTrabajo {
  id: number;
  descripcion: string;
  codigo: string;
}

export class UnidadNegocio {
  id: number;
  descripcion: string;
//  centros_trabajo: CentroTrabajo;
}

export class Proveedor{
  id: number;
  descripcion: string;
  unidad_negocio: UnidadNegocio
}



export class TipoLiquidacion{
  id: number;
  descripcion: string;
  garante: Garante
}

export class Liquidacion {
  id: number;
  descripcion: string;
  importe: number;
  fecha_fin: Date;
  fecha_inicio: Date;
  liquidacion_file: LiquidacionFile;
  proveedor: Proveedor;
  unidad_negocio: UnidadNegocio;
  tipo_liquidacion: TipoLiquidacion;
}

export class TareaConciliacion {
  id: number;
  conciliacion_grupo: string[];
  delete_no_validado: boolean;
  validar_cobro: boolean;
  fecha: Date;
  unidades_negocio: string[];
  garantes: string[];
}

export class Conciliacion {
  id: number;
  task_id: string;
}

export class Validacion {
  id: number;
  file: string;
}

export class ValidacionSheet {
  id: number;
  validacion_file_id: number;
  sheet_name: string;
}

