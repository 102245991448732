import {NgModule} from "@angular/core";
import { CommonModule } from '@angular/common';

import { ConciliacionRoutingModule } from './conciliacion-routing.module';

import {MaterialModule}  from "app/modules/material.module";
import {ReactiveFormsModule} from "@angular/forms";

import { ConciliacionComponent } from './conciliacion.component';
import { ValidacionSheetComponent } from './validacion/validacion-sheet/validacion-sheet.component';
import { LiquidacionListComponent } from './liquidacion/liquidacion-list/liquidacion-list.component';
import { LiquidacionDetailComponent } from './liquidacion/liquidacion-detail/liquidacion-detail.component';
import { LiquidacionFileListComponent } from './liquidacion/liquidacion-file-list/liquidacion-file-list.component';
import { ConciliacionFileListComponent } from './conciliacion/conciliacion-file-list/conciliacion-file-list.component';
import { ConciliacionCreateComponent } from './conciliacion/conciliacion-create/conciliacion-create.component';
import { ValidacionFileListComponent } from './validacion/validacion-file-list/validacion-file-list.component';
import { FacturacionFileListComponent } from './facturacion/facturacion-file-list/facturacion-file-list.component';
import { DeudaFileListComponent } from './deuda/deuda-file-list/deuda-file-list.component';
import { ValidacionCreateComponent } from './validacion/validacion-create/validacion-create.component';
import { DeudaCreateComponent } from './deuda/deuda-create/deuda-create.component';
import { FacturacionCreateComponent } from './facturacion/facturacion-create/facturacion-create.component';
import { LiquidacionCreateComponent } from './liquidacion/liquidacion-create/liquidacion-create.component';
import { UnidadNegocioComponent } from './unidad-negocio/unidad-negocio.component';
import { UnidadNegocioSelectChipsComponent } from './unidad-negocio/unidad-negocio-select-chips/unidad-negocio-select-chips.component';
import { UnidadNegocioSelectComponent } from './unidad-negocio/unidad-negocio-select/unidad-negocio-select.component';
import {UnidadNegocioDataSource} from "app/modules/conciliacion/core/LiquidacionesDataSource";
import { ValidacionDetailComponent } from './validacion/validacion-detail/validacion-detail.component';
import {ControlPanelComponent} from "adtk/control-panel";
import {SelectChipsComponent} from "adtk/select-chips";
import {UploadFileComponent} from "adtk/upload-file";
import {DownloadProgressComponent} from "adtk/download";
import { FileListComponent } from "adtk/cloud-file";

@NgModule({
  declarations: [
  ValidacionSheetComponent,
  ConciliacionComponent,
  LiquidacionListComponent,
  LiquidacionDetailComponent,
  LiquidacionFileListComponent,
  ConciliacionFileListComponent,
  ConciliacionCreateComponent,
  ValidacionFileListComponent,
  FacturacionFileListComponent,
  DeudaFileListComponent,
  ValidacionCreateComponent,
  DeudaCreateComponent,
  FacturacionCreateComponent,
  LiquidacionCreateComponent,
  UnidadNegocioComponent,
  UnidadNegocioSelectChipsComponent,
  UnidadNegocioSelectComponent,
  ValidacionDetailComponent],
  exports: [],
    providers: [UnidadNegocioDataSource],
    imports: [MaterialModule,
        CommonModule,
        ReactiveFormsModule,
        ConciliacionRoutingModule,
        ControlPanelComponent,
        SelectChipsComponent,
        DownloadProgressComponent,
        UploadFileComponent,
      FileListComponent
    ]
})
export class ConciliacionModule { }
