import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conciliacion',
  templateUrl: './conciliacion.component.html',
  styleUrls: ['./conciliacion.component.scss']
})
export class ConciliacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
