import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NotauthorizedComponent} from "./notauthorized/notauthorized.component";


const routes: Routes = [
  { path: 'notauthorized',  component: NotauthorizedComponent},

    //{ path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: '',  redirectTo: '/conciliacion/conciliacion', pathMatch: 'full' },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
   // { enableTracing: true } // <-- debugging purposes only
    ),
  ], //, { enableTracing: true }
  exports: [RouterModule]
})
export class AppRoutingModule { }
