<div *ngIf="status=='init'">
  <p>Seleccione las compañías procesar:</p>
  <app-select-chips [elements]="garantes" [allElements]="allGarantes" placeholder="Compañías..."></app-select-chips>
  <p>Suba el fichero Excel con la actualización de la deuda.</p>
  <app-upload-file (changeFile)="onChangeFile($event)"></app-upload-file>
</div>

<div *ngIf="status=='uploading'">
  <mat-progress-spinner
    class="example-margin"
    color="primary"
    [value]="upload_progress"
    mode="determinate">
  </mat-progress-spinner>
  <p>Enviando fichero... </p>
</div>

<div *ngIf="status=='processing'">
  <mat-progress-spinner
    class="example-margin"
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
  <p>Procesando datos... </p>
</div>

