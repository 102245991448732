
import {
  ConciliacionFile, DeudaFile,
  Liquidacion,
  LiquidacionFile, FacturacionFile,
  UnidadNegocio, ValidacionFile
} from "app/modules/conciliacion/_models/liquidacion";
import {
  ConciliacionFileService, DeudaFileService,
  LiquidacionFileService,
  LiquidacionService, FacturacionFileService,
  UnidadNegocioService, ValidacionFileService
} from "app/modules/conciliacion/_services/liquidacion.service";
import {Injectable} from "@angular/core";
import {EntityDataSource} from "adtk/remote-entity";


@Injectable()
export class ConciliacionFileDataSourceService extends EntityDataSource<ConciliacionFile> {

    constructor(dataService: ConciliacionFileService) {
      super(dataService);
    }

}


@Injectable()
export class FacturacionFileDataSourceService extends EntityDataSource<FacturacionFile> {

    constructor(dataService: FacturacionFileService) {
      super(dataService);
    }

}

@Injectable()
export class DeudaFileDataSourceService extends EntityDataSource<DeudaFile> {

    constructor(dataService: DeudaFileService) {
      super(dataService);
    }

}


@Injectable()
export class ValidacionFileDataSourceService extends EntityDataSource<ValidacionFile> {

    constructor(dataService: ValidacionFileService) {
      super(dataService);
    }

}

@Injectable()
export class LiquidacionFileDataSourceService extends EntityDataSource<LiquidacionFile> {

    constructor(dataService: LiquidacionFileService) {
      super(dataService);
    }

}


@Injectable()
export class LiquidacionDataSourceService extends EntityDataSource<Liquidacion> {

    constructor(dataService: LiquidacionService) {
      super(dataService);
    }

}

@Injectable()
export class UnidadNegocioDataSource extends EntityDataSource<UnidadNegocio> {

    constructor(dataService: UnidadNegocioService) {
      super(dataService);
    }

}
