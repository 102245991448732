import { Component, OnInit } from '@angular/core';
import {HttpRequestProgress, ValidacionFileService} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AsyncTask, AsyncTaskService} from "adtk/async-task";
import {ActionButton, ControlPanelService} from "adtk/control-panel";

@Component({
  selector: 'app-validacion-create',
  templateUrl: './validacion-create.component.html',
  styleUrls: ['./validacion-create.component.scss']
})
export class ValidacionCreateComponent implements OnInit {

  file: File;
  task_id = '';
  validacion: any;
  validacion_id: number;//= 116;
  upload_progress: number;
  status: string = "init";

  private actionIniciar: ActionButton = { name: 'Iniciar', click: () => this.onIniciar(), enabled:true, disabled:true, color: "" };
  private actionCancel: ActionButton = { name: 'Cancelar', click: () => this.onCancelar(), enabled:true, color: "accent" };

  constructor(private validacionFileService: ValidacionFileService, private controlPanelService: ControlPanelService, private asyncTaskService: AsyncTaskService, public router: Router, public r:ActivatedRoute, private _location: Location) {
      this.controlPanelService.title = "Iniciar nueva validación";
      this.controlPanelService.subtitle = "Inicia el proceso de validación de conciliaciones";
      this.controlPanelService.actionButtons = [this.actionIniciar, this.actionCancel];
      this.controlPanelService.secondaryActionButtons = [];
      this.controlPanelService.statusbarButtons = [];
  }

  ngOnInit() {
  }


  onChangeFile(file: File): void {
    this.file = file;
    if (file) {
      this.actionIniciar.disabled = false;
    } else {
      this.actionIniciar.disabled = true;
    }
  }

  onIniciar() {
    if (this.file) {
      this.status = "uploading";
      this.validacionFileService.createFromFile(this.file)
        .subscribe(ret => this.setTask(ret));
    }
  }

  onCancelar() {
      this._location.back();
  }

  setTask(response: HttpRequestProgress) {
    switch (response.status) {
      case "done":
        this.status = "processing";
        const message = response.message;
        this.task_id = message.task_id;
        this.validacion = message;
        if (!this.task_id) {
          return;
        }
        this.actionIniciar.disabled = true;
        this.actionCancel.disabled = true;
        this.asyncTaskService.getAsyncTaskObserver(this.task_id)
                .subscribe(asyncTask => this.setTaskStatus(asyncTask));

        break;
      case "progress":
        this.upload_progress = response.percent;
        break;
    };

  }

  setTaskStatus(asyncTask: AsyncTask) {
    if (!asyncTask || asyncTask.status == 'SUCCESS') {
      console.log(asyncTask.result);
      this.task_id = '';
      this.status = "success";
      this.actionIniciar.enabled = false;
      this.validacion_id = this.validacion.id;
    }
  }


}
