import { Component } from '@angular/core';
import {FacturacionFile} from "app/modules/conciliacion/_models/liquidacion";
import {FacturacionFileDataSourceService} from "app/modules/conciliacion/core/LiquidacionesDataSource";
import {FacturacionFileService} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EntitiesComponent} from "adtk/remote-entity";
import {ActionButton, ControlPanelService} from "adtk/control-panel";

@Component({
  selector: 'app-facturacion-file-list',
  templateUrl: './facturacion-file-list.component.html',
  providers: [ FacturacionFileDataSourceService ],
  styleUrls: ['./facturacion-file-list.component.scss']
})
export class FacturacionFileListComponent  extends EntitiesComponent<FacturacionFile>  {

    private actionCrear: ActionButton = { name: 'Importar', click: () => this.onImportar(), enabled:true, color: "primary" };

    constructor(public override dataSource: FacturacionFileDataSourceService,
                private facturacionFileService: FacturacionFileService,
                private controlPanelService: ControlPanelService,
                private router: Router, private r:ActivatedRoute) {
      super(dataSource, facturacionFileService);
      this.displayedColumns = ['select','descripcion', 'file_date'];
      this.controlPanelService.title = "Listados Facturación";
      this.controlPanelService.subtitle = "Importación de listados de facturación: recapitulativas, SAP, xHIS.";
      this.controlPanelService.actionButtons = [this.actionCrear];
      this.controlPanelService.secondaryActionButtons = [];
      this.controlPanelService.statusbarButtons = [];
    }

  override ngOnInit() {
      super.ngOnInit();
      this.matSort.sort({id:'file_date', start:'desc', disableClear:true})
    }

  onImportar() {
      this.router.navigate(["nueva"], { relativeTo: this.r });
  }

  download(file_id: string) {
    this.facturacionFileService.downloadFile(file_id);
  }


}
