
  <ng-container #fileSize matColumnDef="file-size">
    <th mat-header-cell *matHeaderCellDef > Tamaño </th>
    <td mat-cell *matCellDef="let element"> {{element.file_size}} </td>
  </ng-container>


<app-file-list #someInput>
</app-file-list>


    <div class="spinner-container" *ngIf="dataSource.loading$ | async ">
        <mat-spinner></mat-spinner>
    </div>

  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

    <!-- Checkbox Column -->
     <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell class="min-width" *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>


  <ng-container matColumnDef="download-file">
    <th mat-header-cell class="min-width" *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button matTooltip="Descargar fichero" (click)="download(element.file_id)"><mat-icon inline="true">cloud_download</mat-icon></button>
    </td>
  </ng-container>

  <ng-container matColumnDef="download-pdf">
    <th mat-header-cell class="min-width" *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.pdf_cloud_file">
      <button mat-icon-button matTooltip="Descargar PDF" (click)="download(element.pdf_cloud_file.file_id)"><mat-icon inline="true">picture_as_pdf</mat-icon></button>
        </ng-container>
    </td>
  </ng-container>

      <!-- Name Column -->
  <ng-container matColumnDef="garante">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="garante"> Garante </th>
    <td mat-cell *matCellDef="let element"> {{element.garante?.nombre}} </td>
  </ng-container>


  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell class="right" *matHeaderCellDef> Id </th>
    <td mat-cell class="right" *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="descripcion">
    <th mat-header-cell *matHeaderCellDef> Nombre </th>
    <td mat-cell *matCellDef="let element"> <a [routerLink]="['/conciliacion/liquidacion']" [queryParams]="{file_id: element.id}">{{element.file_name}}</a> </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="file_date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="file_date"> Fecha </th>
    <td mat-cell *matCellDef="let element"> {{element.file_date | date:'dd/MM/yyyy HH:mm'}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

    <!--<mat-paginator [length]="course?.lessonsCount" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

