import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {FileCreateComponent, FileListComponent} from "adtk/cloud-file";
import {CloudfileComponent} from "./cloudfile.component";
import {LcolleniAuthGuard} from "../../auth/keycloak/keycloak.authguard";


const cloudfileRoutes: Routes = [
  {
    path: 'cloudfile',
    component: CloudfileComponent,
    canActivate: [LcolleniAuthGuard],
    data: {roles: ["conciliacion-vithasmalaga",]},
    children: [
      {path: 'file-create', component: FileCreateComponent},
      {path: 'file-list', component: FileListComponent},
      { path: '',  redirectTo: 'file-create', pathMatch: 'prefix' },
    ]
  }
  ];

@NgModule({
  imports: [RouterModule.forChild(cloudfileRoutes)],
  exports: [RouterModule]
})
export class CloudFileRoutingModule { }
