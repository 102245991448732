/* "Barrel" of Http Interceptors */
import {APP_INITIALIZER} from "@angular/core";
import {KeycloakService} from "keycloak-angular";
import {environment} from "../../../environments/environment";

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://auth.k8s.abolus.com',
        realm: 'vithas',
        clientId: 'lcolleni',
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + environment.contextroot + '/assets/silent-check-sso.html'
      }
    });
}

/** Http interceptor providers in outside-in order */
export const KeycloakHttpInterceptorProviders = [
  {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    }
];
