import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from 'app/_services';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})
export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;
    classes: string;

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            this.message = message;
            this._updateClasses();
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

  private _updateClasses() {
    this.classes = `${this.message ? 'alert': ''} ${this.message && this.message.type === 'success' ? ' alert-success': ''}${
      this.message &&  this.message.type==='error' ? ' alert-danger': ''}`;
  }


}
