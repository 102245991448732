<div *ngIf="status=='init'">
  <p>Suba el fichero Excel con los datos a validar. Puede validar lineas de liquidación y de facturas.
    </p>
  <h3>Validación de liquidaciones</h3>
<p>Para validar líneas de liquidaciones, la primera y segunda columna indicarán número de liquidación y línea
    a validar. La primera fila incluirá las cabeceras "liquidacion_id" e "id".</p>

  <app-upload-file (changeFile)="onChangeFile($event)"></app-upload-file>

</div>

<div *ngIf="status=='uploading'">
  <mat-progress-spinner
    class="example-margin"
    color="primary"
    [value]="upload_progress"
    mode="determinate">
  </mat-progress-spinner>
  <p>Enviando fichero... </p>
</div>

<div *ngIf="status=='processing'">
  <mat-progress-spinner
    class="example-margin"
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
  <p>Procesando datos... </p>
</div>
