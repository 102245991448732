<div id="top-of-page"></div>
<!--
<aio-cookies-popup></aio-cookies-popup>
-->
<a class="skip-to-content-link" href="#main-content">Skip to main content</a>

<div *ngIf="isFetching" class="progress-bar-container">
 <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
</div>

<header>
  <mat-toolbar #appToolbar color="primary" class="app-toolbar no-print" [class.transitioning]="isTransitioning">
  <mat-toolbar-row>
      <button mat-button [class.no-animations]="disableAnimations" (click)="sidenav.toggle()" title="Docs menu">
        <mat-icon svgIcon="menu"></mat-icon>
      </button>
<!--      <a class="nav-link home" href="/" [ngSwitch]="showTopMenu">
        <img *ngSwitchCase="true" src="assets/images/logos/angular/logo-nav@2x.png" width="150" height="40" title="Home" alt="Home">
        <img *ngSwitchDefault src="assets/images/logos/angular/shield-large.svg" width="37" height="40" title="Home" alt="Home">
      </a>
      -->
      <!--<aio-top-menu *ngIf="showTopMenu" [nodes]="topMenuNodes" [currentNode]="currentNodes.TopBar"></aio-top-menu>
-->
    <!-- Logo -->
    <div class="logo">
      <a class="nav-link home" href="/">
        <span>Conciliación de Cobros</span>
      </a>
    </div>
    <!-- / Logo -->

  <span class="spacer"></span>

    <!--<aio-search-box class="search-container" #searchBox (onSearch)="doSearch($event)" (onFocus)="doSearch($event, true)"></aio-search-box>
    -->
    <aio-theme-toggle #themeToggle></aio-theme-toggle>
    <ng-container *ngIf="userProfile">
      <app-async-task-dropdown></app-async-task-dropdown>
      <button mat-button (click)="logout()">
        <span>Cerrar sesión</span>
      </button>
    </ng-container>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<aio-search-results *ngIf="showSearchResults"
  #searchResultsView
  [searchResults]="searchResults | async"
  (resultSelected)="hideSearchResults()"
  (closeButtonClick)="hideSearchResults()">
</aio-search-results>

<mat-sidenav-container class="sidenav-container" [class.no-animations]="disableAnimations" [class.has-floating-toc]="hasFloatingToc">

  <mat-sidenav [class.collapsed]="!dockSideNav" #sidenav class="sidenav" [mode]="mode" [opened]="isOpened" (openedChange)="updateHostClasses()">
    <aio-nav-menu *ngIf="!showTopMenu" [nodes]="topMenuNarrowNodes" [currentNode]="currentNodes.TopBarNarrow" [isWide]="dockSideNav" navLabel="primary"></aio-nav-menu>
    <aio-nav-menu [nodes]="sideNavNodes" [currentNode]="currentNodes.SideNav" [isWide]="dockSideNav" navLabel="guides and docs"></aio-nav-menu>

    <div class="doc-version">
      <aio-nav-menu [nodes]="docVersions" [isWide]="true" [currentNode]="currentDocsVersionNode" navLabel="docs versions"></aio-nav-menu>
    </div>
  </mat-sidenav>

  <section class="sidenav-content-container">

    <main class="sidenav-content" [id]="pageId">
      <div id="main-content" tabindex="-1"></div>
    <!-- Content -->
    <ng-container>
      <!-- main app container -->
       <alert></alert>
       <router-outlet></router-outlet>
    </ng-container>
    <!-- / Content -->

   </main>

 <!--   <div *ngIf="hasFloatingToc" class="toc-container no-print" [style.max-height.px]="tocMaxHeight" (wheel)="restrainScrolling($event)">
      <aio-lazy-ce selector="aio-toc"></aio-lazy-ce>
    </div>
-->
  </section>

<!--  <footer class="no-print">
    <aio-footer [nodes]="footerNodes"></aio-footer>
  </footer>
-->
</mat-sidenav-container>

