import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpParams, HttpRequest} from '@angular/common/http';

import {
  CloudFileBase, Conciliacion, ConciliacionFile, DeudaFile,
  Liquidacion, LiquidacionFile, FacturacionFile, TareaConciliacion,
  UnidadNegocio,
  Validacion, ValidacionFile,
  ValidacionSheet
} from "app/modules/conciliacion/_models/liquidacion";

import {Observable} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {EntityService} from "adtk/remote-entity";
import {APP_CONFIG, AppConfig} from "adtk/app-config";
import {MessageService} from "adtk/message";
import {DownloadService} from "adtk/download";

export class HttpRequestProgress {
status: string;
message: any;
percent: number;
}

abstract class AbstractCloudFileService<T extends CloudFileBase> extends EntityService<T>{

  abstract getContainers(): string[];

  constructor(http: HttpClient, messageService: MessageService, protected downloads: DownloadService) {
    super(http, messageService);
  }

  downloadFile(petition:Object, filename?: string, base_params?: HttpParams) {
      if (!base_params) {
        base_params = new HttpParams();
      }
      var jsonString = JSON.stringify(petition);
      base_params = base_params.set('petition', jsonString);
      base_params = base_params.set('download', "True");
      return this.downloads.download_post(this.getDataUrl(), filename, base_params)
  }

  createFromFile(file: File, formData?: FormData): Observable<HttpRequestProgress> {
    if (!formData) {
      formData = new FormData();
    }
    formData.append('cloudFile', file);
    const req = new HttpRequest('POST', this.getDataUrl(), formData, {
        reportProgress: true
    });
    return this.http.request(req)
      .pipe(
        map(event => this.getEventMessage(event, file)),
        tap(message => console.log(message)),
        catchError(this.handleError('createFromFile', {status: 'error', message: null, percent: 0 }))
    );
  }

  /** Return distinct message for sent, upload progress, & response events */
  private getEventMessage(event: HttpEvent<any>, _file: File): HttpRequestProgress {
    switch (event.type) {
      case HttpEventType.Sent:
//        return `Uploading file "${file.name}" of size ${file.size}.`;
        return { status: 'progress', message: null, percent: 0 };

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        // @ts-ignore
        const percentDone = Math.round(100 * event.loaded / event.total);
        return { status: 'progress', message: null, percent: percentDone };

      case HttpEventType.Response:
        return { status: 'done', message: event.body, percent: 100 };

      default:
        return { status: event.type.toString(), message: null, percent: 0 };
//        return `File "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

  private add_containers_param(base_params?: HttpParams): HttpParams | undefined {
    let containers = this.getContainers();
    if (!containers) {
      return;
    }
    if (!base_params) {
      base_params = new HttpParams();
    }
    containers.forEach((container: string) => {
      // @ts-ignore
      base_params = base_params.append(`containers[]`, container);
    });
    return base_params;
  }

  override count(filter = '', base_params?: HttpParams): Observable<number> {
    base_params = this.add_containers_param(base_params);
    return super.count(filter, base_params);
  }

  override findAll(
    filter = '', sortActive='', sortDirection = 'asc',
    pageNumber = 0, pageSize = 10, base_params?: HttpParams):  Observable<T[]> {
    base_params = this.add_containers_param(base_params);
    return super.findAll(filter, sortActive, sortDirection, pageNumber, pageSize, base_params);
  }

}

@Injectable({
  providedIn: 'root'
})
export class ConciliacionFileService extends AbstractCloudFileService<ConciliacionFile>{

  private dataUrl = `${this.config.apiEndpoint}/conciliacion_file/`;
  private containers = ['conciliaciones'];

  constructor(@Inject(APP_CONFIG) private config: AppConfig, http: HttpClient,
              messageService: MessageService, download: DownloadService) {
    super(http, messageService, download);
  }

  getDataUrl(): string {
    return  this.dataUrl;
  }

  getContainers(): string[] {
    return this.containers;
  }

}


@Injectable({
  providedIn: 'root'
})
export class LiquidacionFileService extends AbstractCloudFileService<LiquidacionFile>{

  private dataUrl = `${this.config.apiEndpoint}/liquidacion_file/`;
  private containers = ['liquidaciones'];

  constructor(@Inject(APP_CONFIG) private config: AppConfig, http: HttpClient,
              messageService: MessageService, download: DownloadService) {
    super(http, messageService, download);
  }

  getDataUrl(): string {
    return  this.dataUrl;
  }

  getContainers(): string[] {
    return this.containers;
  }

  deleteAll(liquidacionFiles: LiquidacionFile[], base_params?: HttpParams) {
    if (!base_params) {
      base_params = new HttpParams();
    }

    base_params = base_params.append('liquidacion_file_id', liquidacionFiles.map(value => value.id).join(","))
    return this.http.delete<any>(this.getDataUrl(), {
            params: base_params
        }).pipe(
           tap(_ => this.log('delete')),
           catchError(this.handleError<number>('delete', undefined))
        );
  }

  processAll(liquidacionFiles: LiquidacionFile[], base_params?: HttpParams) {
      if (!base_params) {
        base_params = new HttpParams();
      }
      //var jsonString = JSON.stringify(petition);
      //base_params = base_params.set('petition', jsonString);
      //base_params = base_params.set('download', "True");
    base_params = base_params.append('liquidacion_file_id', liquidacionFiles.map(value => value.id).join(","))
    base_params = base_params.append('procesar', true)
    return this.http.post<any>(this.getDataUrl(), base_params
        ).pipe(
           tap(_ => this.log('procesar')),
           catchError(this.handleError<number>('procesar', undefined))
        );
  }

}


@Injectable({
  providedIn: 'root'
})
export class FacturacionFileService extends AbstractCloudFileService<FacturacionFile>{

  private dataUrl = `${this.config.apiEndpoint}/facturacion_file/`;
  private containers = ['facturacion'];

  constructor(@Inject(APP_CONFIG) private config: AppConfig, http: HttpClient,
              messageService: MessageService, download: DownloadService) {
    super(http, messageService, download);
  }

  getDataUrl(): string {
    return  this.dataUrl;
  }

  getContainers(): string[] {
    return this.containers;
  }

}

@Injectable({
  providedIn: 'root'
})
export class DeudaFileService extends AbstractCloudFileService<DeudaFile>{

  private dataUrl = `${this.config.apiEndpoint}/deuda_file/`;
  private containers = ['deuda'];

  constructor(@Inject(APP_CONFIG) private config: AppConfig, http: HttpClient,
              messageService: MessageService, download: DownloadService) {
    super(http, messageService, download);
  }

  getDataUrl(): string {
    return  this.dataUrl;
  }

  getContainers(): string[] {
    return this.containers;
  }

  createFromFileAndGarantes(file: File, garantes_id: number[]) {
    const formData = new FormData();
    formData.append('garantes_id', garantes_id.join(","))
    return super.createFromFile(file, formData);
  }

}


@Injectable({
  providedIn: 'root'
})
export class ValidacionFileService extends AbstractCloudFileService<ValidacionFile>{

  private dataUrl = `${this.config.apiEndpoint}/validacion_file/`;
  private containers = ['validacion'];

  constructor(@Inject(APP_CONFIG) private config: AppConfig, http: HttpClient,
              messageService: MessageService, download: DownloadService) {
    super(http, messageService, download);
  }

  getDataUrl(): string {
    return  this.dataUrl;
  }

  getContainers(): string[] {
    return this.containers;
  }

  deleteAll(validacionFiles: ValidacionFile[], base_params?: HttpParams) {
  if (!base_params) {
    base_params = new HttpParams();
  }

  base_params = base_params.append('validacion_file_id', validacionFiles.map(value => value.id).join(","))
  return this.http.delete<any>(this.getDataUrl(), {
          params: base_params
      }).pipe(
         tap(_ => this.log('delete')),
         catchError(this.handleError<number>('delete', undefined))
      );
}

}

@Injectable({
  providedIn: 'root'
})
export class LiquidacionService extends EntityService<Liquidacion>{

  private dataUrl = `${this.config.apiEndpoint}/liquidaciones/`;
  public container: string;

  constructor(@Inject(APP_CONFIG) private config: AppConfig, http: HttpClient,
              messageService: MessageService) {
    super(http, messageService);
  }

  getDataUrl(): string {
    return  this.dataUrl;
  }

  updateUnidadNegocio(liquidacion_id: string, unidad_negocio_id: string, formData?: FormData): Observable<UnidadNegocio> {
    if (!formData) {
      formData = new FormData();
    }
    formData.append('liquidacion_id', liquidacion_id);
    formData.append('unidad_negocio_id', unidad_negocio_id);
    return this.http.patch<UnidadNegocio>(this.getDataUrl(), formData).pipe(
           tap(_ => this.log('fetched entities')),
           catchError(this.handleError<UnidadNegocio>('updateUnidadNegocio', undefined))
         // , map(res =>  res:)
        );
  }


}


@Injectable({
  providedIn: 'root'
})
export class UnidadNegocioService extends EntityService<UnidadNegocio>{

  private dataUrl = `${this.config.apiEndpoint}/UN/`;

  constructor(@Inject(APP_CONFIG) private config: AppConfig, http: HttpClient,
              messageService: MessageService) {
    super(http, messageService);
  }

  getDataUrl(): string {
    return  this.dataUrl;
  }

}

@Injectable({
  providedIn: 'root'
})
export class ValidacionSheetService extends EntityService<ValidacionSheet>{

  private dataUrl = `${this.config.apiEndpoint}/validaciones_sheet/`;

  constructor(@Inject(APP_CONFIG) private config: AppConfig, http: HttpClient,
              messageService: MessageService) {
    super(http, messageService);
  }

  getDataUrl(): string {
    return  this.dataUrl;
  }

  findByValidacionHistId(validacion_file_id: number) {
    return this.http.get<any>(this.getDataUrl(), {
      params: new HttpParams()
        .set('validacion_file_id', String(validacion_file_id))
//                .set('filter', filter)
    } ).pipe(
      tap(_ => this.log('fetched entities')),
      catchError(this.handleError<Validacion[]>('findByValidacionHistId', []))
      // , map(res =>  res:)
    );
  }


  validate(validacion_sheet_ids: number[]): Observable<string> {
    return this.http.post<string>(this.getDataUrl(), validacion_sheet_ids, {responseType: 'json'}).pipe(
      tap(_ => this.log('task send')),
      catchError(this.handleError<Validacion[]>('validate', []))
      , map(data => (data as any).task_id)
    );
  }




}





@Injectable({
  providedIn: 'root'
})
export class ConciliacionService extends EntityService<TareaConciliacion>{

  private dataUrl = `${this.config.apiEndpoint}/conciliaciones/`;

  constructor(@Inject(APP_CONFIG) private config: AppConfig, http: HttpClient,
              messageService: MessageService) {
    super(http, messageService);
  }

  getDataUrl(): string {
    return  this.dataUrl;
  }

  create(tareaConciliacion: TareaConciliacion, _base_params?: HttpParams) {
    return this.http.post<Conciliacion>(this.getDataUrl(), tareaConciliacion).pipe(
           tap(_ => this.log('create')),
           catchError(this.handleError<Conciliacion>('create', undefined))
        );
  }

}

