<div class="spinner-container" *ngIf="dataSource.loading$ | async ">
   <mat-spinner></mat-spinner>
</div>

<table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

    <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell class="right" *matHeaderCellDef> Id </th>
    <td mat-cell class="right" *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

    <!-- Name Column -->
  <ng-container matColumnDef="unidad_negocio">
    <th mat-header-cell *matHeaderCellDef> Unidad de Negocio </th>
    <td mat-cell *matCellDef="let element">
      <app-unidad-negocio-select [unidadNegocio]="element.unidad_negocio" (unidadNegocioChanged)="updateUnidadNegocio(element, $event)"></app-unidad-negocio-select>
    </td>
  </ng-container>

      <!-- Name Column -->
  <ng-container matColumnDef="garante">
    <th mat-header-cell *matHeaderCellDef> Garante </th>
    <td mat-cell *matCellDef="let element"> {{element.tipo_liquidacion.garante?.nombre}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="descripcion">
    <th mat-header-cell *matHeaderCellDef> Descripción </th>
    <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
  </ng-container>

    <!-- Name Column -->
  <ng-container matColumnDef="fecha_fin">
    <th mat-header-cell *matHeaderCellDef> Fecha Fin </th>
    <td mat-cell *matCellDef="let element"> {{element.fecha_fin | date:'dd/MM/yy'}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="importe">
    <th mat-header-cell class="right" *matHeaderCellDef> Importe </th>
    <td mat-cell class="right" *matCellDef="let element"> {{element.importe | number:'1.2-2':'es'}} </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<!--<mat-paginator [length]="course?.lessonsCount" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

