<div *ngIf="sheetForm">
  <div *ngIf="!task_id && !asyncTask">
    <p>
      Seleccione hojas a validar.
    </p>
    <form [formGroup]="sheetForm" (ngSubmit)="submit()">
      <div class="checkbox-list">
      <label formArrayName="sheets" *ngFor="let sheetControl of getControls(); let i = index">
         <mat-checkbox [formControlName]="i">
            {{sheets[i].sheet_name}}
          </mat-checkbox>
      </label>
      </div>
      <button type="submit" mat-raised-button> <mat-icon inline="true">done</mat-icon> Validar</button>
    </form>
  </div>

  <div *ngIf="task_id">
    <mat-progress-spinner
      class="example-margin"
      color="primary"
      mode="indeterminate">
    </mat-progress-spinner>
    <p>Procesando datos...</p>
  </div>

    <div *ngIf="asyncTask">
      <p>Se ha completado la validación.</p>
      <ul class="sheets">
<!--  <li *ngFor="let sheet of asyncTask.result">
    <span class="badge">{{sheet.id}}</span> {{sheet.sheet_name}}: {{sheet.rowcount}} registros.
  </li>
  -->
</ul>
    </div>

</div>
