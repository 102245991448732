import {Component, Input, OnInit} from '@angular/core';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from "@angular/forms";
import {ValidacionSheetService} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Params} from "@angular/router";
import {AsyncTask, AsyncTaskService} from "adtk/async-task";

@Component({
  selector: 'app-validacion-sheet',
  templateUrl: './validacion-sheet.component.html',
  styleUrls: ['./validacion-sheet.component.scss']
})
export class ValidacionSheetComponent implements OnInit {
   sheetForm: UntypedFormGroup;
   private _sheets: any[];
   private _validacion_id: number;
   task_id? = '';
   asyncTask?: AsyncTask;
   validacion: any;


  constructor(private fb: UntypedFormBuilder, private validacionSheetService: ValidacionSheetService, private asyncTaskService: AsyncTaskService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => this.validacion_id = params['validacion_id']);
  }

  get validacion_id() {
    return this._validacion_id
  }

  @Input() set validacion_id(validacion_id) {
    this._validacion_id = validacion_id;
    this.validacionSheetService.findByValidacionHistId(this._validacion_id)
      .subscribe(sheets => this.sheets = sheets);
  }

  get sheets() {
    return this._sheets;
  }

  set sheets(sheets) {
    this._sheets = sheets;
        // Create a FormControl for each available preference, initialize them as unchecked, and put them in an array
    const formControls = this._sheets.map( (_: any) => new UntypedFormControl(false));

    // Simply add the list of FormControls to the FormGroup as a FormArray
    this.sheetForm = this.fb.group({
      sheets: new UntypedFormArray(formControls)
    });

  }

  getControls() {
    return (this.sheetForm.get('sheets') as FormArray).controls;
  }


  submit() {
    // Filter out the unselected ids
    const selectedPreferences = this.sheetForm.value.sheets
      .map((checked: any, index: number) => checked ? this.sheets[index].id : null)
      .filter((value: null) => value !== null);
    // Do something with the result
    console.warn(selectedPreferences);
    if (selectedPreferences.length > 0) {
      this.validacionSheetService.validate(selectedPreferences)
        .subscribe(task => this.setTask(task))
    }
  }

  setTask(task_id: string) {
    this.task_id = task_id;
    if (!this.task_id) {
      return;
    }
    this.asyncTaskService.getAsyncTaskObserver(this.task_id)
      .subscribe((asyncTask) => {
          this.setTaskStatus(asyncTask);
        }
      );
  }

  setTaskStatus(asyncTask: AsyncTask) {
    if (!asyncTask || asyncTask.status == 'SUCCESS') {
      this.task_id = '';
      this.asyncTask = asyncTask;
    }
  }

  reiniciar() {
    this.task_id = undefined;
    this.asyncTask = undefined;
  }






}
