import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { CloudfileComponent } from './cloudfile.component';
import {ControlPanelComponent} from "adtk/control-panel";
import { FileListComponent } from "adtk/cloud-file";
import {CloudFileRoutingModule} from "./cloudfile-routing.module";



@NgModule({
  declarations: [
    CloudfileComponent
  ],
  imports: [
    CommonModule,
    ControlPanelComponent,
    CloudFileRoutingModule,
    FileListComponent
  ],
})
export class LcolleniCloudfileModule { }
