import { Component } from '@angular/core';
import {
  DeudaFileDataSourceService
} from "app/modules/conciliacion/core/LiquidacionesDataSource";
import {DeudaFile} from "app/modules/conciliacion/_models/liquidacion";
import {DeudaFileService} from "app/modules/conciliacion/_services/liquidacion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EntitiesComponent} from "adtk/remote-entity";
import {ActionButton, ControlPanelService} from "adtk/control-panel";

@Component({
  selector: 'app-deuda-file-list',
  templateUrl: './deuda-file-list.component.html',
  providers: [ DeudaFileDataSourceService ],
  styleUrls: ['./deuda-file-list.component.scss']
})
export class DeudaFileListComponent extends EntitiesComponent<DeudaFile>  {

    private actionCrear: ActionButton = { name: 'Importar', click: () => this.onImportar(), enabled:true, color: "primary" };

    constructor(public override dataSource: DeudaFileDataSourceService,
                private deudaFileService: DeudaFileService,
                private controlPanelService: ControlPanelService,
                private router: Router, private r:ActivatedRoute) {
      super(dataSource, deudaFileService);
      this.displayedColumns = ['select','descripcion', 'garantes', 'file_date'];
      this.controlPanelService.title = "Importación de deuda";
      this.controlPanelService.subtitle = "Ficheros de carga de deuda actual.";
      this.controlPanelService.actionButtons = [this.actionCrear];
      this.controlPanelService.secondaryActionButtons = [];
      this.controlPanelService.statusbarButtons = [];
    }

  override ngOnInit() {
      super.ngOnInit();
      this.dataSource.asObservable().subscribe(deudafile => console.log(deudafile))
      this.matSort.sort({id:'file_date', start:'desc', disableClear:true})
    }

  onImportar() {
      this.router.navigate(["nueva"], { relativeTo: this.r });
  }

  download(file_id: string) {
    this.deudaFileService.downloadFile(file_id);
  }


}
