import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ConciliacionService} from "app/modules/conciliacion/_services/liquidacion.service";
import {Conciliacion, TareaConciliacion} from "app/modules/conciliacion/_models/liquidacion";
import {AsyncTask} from "adtk/async-task";
import {ActionButton, ControlPanelService} from "adtk/control-panel";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";


export enum ConciliacionGrupoEnum {
  ENSACADORA = 'ENSACADORA',
  SILO8 = 'SILO8',
  SILO9 = 'SILO9'
}
export enum UnidadNegocioEnum {
  ENSACADORA = 'ENSACADORA',
  SILO8 = 'SILO8',
  SILO9 = 'SILO9'
}
export enum GarantesEnum {
  ENSACADORA = 'ENSACADORA',
  SILO8 = 'SILO8',
  SILO9 = 'SILO9'
}
@Component({
  selector: 'app-conciliacion-create',
  templateUrl: './conciliacion-create.component.html',
  styleUrls: ['./conciliacion-create.component.scss']
})
export class ConciliacionCreateComponent implements OnInit {

  private actionIniciar: ActionButton = { name: 'Iniciar', click: () => this.onIniciar(), enabled:true, color: "primary" };
  private actionCancel: ActionButton = { name: 'Cancelar', click: () => this.onCancelar(), enabled:true, color: "accent" };

  conciliacionForm: FormGroup;
  availableConciliacionGrupo: { key: string, value: string }[];
  availableUnidadNegocio: { key: string, value: string }[];
  availableGarantes: { key: string, value: string }[];

  //private conciliacionGrupoIdMap = new Map();
  conciliacionGrupo: string[] = ['Facturación'];
  allConciliacionGrupo: string[] = ['Facturación'];


  private unidadNegocioIdMap = new Map();
  unidadesNegocio: string[] = ['Xanit', 'Parque San Antonio'];
  allUnidadesNegocio: string[] = ['Xanit', 'Parque San Antonio'];

  private garanteIdMap = new Map();
  garantes: string[] = ['Adeslas', 'Asisa', 'Mapfre', 'DKV'];
  allGarantes: string[] = ['Adeslas', 'Asisa', 'Mapfre', 'DKV'];

  tareaConciliacion: TareaConciliacion = new TareaConciliacion();
  conciliacion: Conciliacion;
  task_id = '';

  constructor(private conciliacionService: ConciliacionService,
              private fb: FormBuilder,
              private controlPanelService: ControlPanelService,
              //public asyncTaskService: AsyncTaskService,
              public router: Router, public r:ActivatedRoute, private _location: Location) {
      this.controlPanelService.title = "Iniciar nueva conciliación";
      this.controlPanelService.subtitle = "Inicia el proceso de conciliación de liquidaciones y deuda.";
      this.controlPanelService.actionButtons = [this.actionIniciar, this.actionCancel];
      this.controlPanelService.secondaryActionButtons = [];
      this.controlPanelService.statusbarButtons = [];

        this.unidadNegocioIdMap.set("Xanit", 2);
        this.unidadNegocioIdMap.set("Parque San Antonio", 1);
        this.garanteIdMap.set("Asisa", 1);
        this.garanteIdMap.set("Adeslas", 2);
        this.garanteIdMap.set("Mapfre", 3);
        this.garanteIdMap.set("DKV", 4);

    this.availableConciliacionGrupo = Object.entries(ConciliacionGrupoEnum).map(([key, value]) => ({ key, value }));
    this.availableUnidadNegocio = Object.entries(UnidadNegocioEnum).map(([key, value]) => ({ key, value }));
    this.availableGarantes = Object.entries(GarantesEnum).map(([key, value]) => ({ key, value }));

      this.conciliacionForm = this.fb.group({
        validar_cobro: [false, Validators.required],
        delete_no_validado: [true, Validators.required],
        conciliacion_grupo: [this.conciliacionGrupo],
        unidad_negocio:[this.unidadesNegocio],
        garantes: [this.garantes],
     }
   )

  }

  ngOnInit() {
  }

  getGarantesId(): number[] {
    let garantes_id:number[] = [];
    for (let entry of this.garantes) {
      garantes_id.push(this.garanteIdMap.get(entry))
    }
    return garantes_id;
  }

  getUnidadesNegocioId(): number[] {
    let unidadesNegocio_id:number[] = [];
    for (let entry of this.unidadesNegocio) {
      unidadesNegocio_id.push(this.unidadNegocioIdMap.get(entry))
    }
    return unidadesNegocio_id;
  }

  onIniciar() {
    if (this.garantes.length > 0 && this.unidadesNegocio.length > 0) {
      this.actionIniciar.disabled = true;
      this.conciliacionService.create(this.conciliacionForm.value).subscribe(conciliacion => this.setConciliacion(conciliacion));
    }
  }

  setConciliacion(conciliacion:Conciliacion) {
    this.conciliacion = conciliacion;
    this.task_id = conciliacion.task_id;
    if (!this.task_id) {
      return;
    }
    this.actionCancel.disabled = true;
//    this.asyncTaskService
//      .launch(this.task_id)
//      .subscribe(asyncResult => this.setTaskStatus(asyncResult));
  }

  setTaskStatus(asyncResult: AsyncTask) {
    if (!asyncResult || asyncResult.status == 'SUCCESS') {
      this._location.back();
//      this.task_id = '';
//      this.validacion_id = this.validacion.id;
    }
  }

  onCancelar() {
      this._location.back();
  }

    onSubmit() {
      // TODO: Use EventEmitter with form value
    console.warn(this.conciliacionForm.value);
    this.onIniciar();
  }

}
