<div class="text-wrapper">
  <h1 class="title" data-content="403">
    403 - ACCESO DENEGADO
  </h1>
  <h2 class="subtitle">
    No tiene permiso de acceso a esta página. Contacte con el administrador.
  </h2>
  <div class="isi">
    Error 403 Forbidden es un código de estado HTTP que indica que el servidor deniega la acción solicitada, página web
    o servicio. En otras palabras, el servidor ha podido ser contactado, y ha recibido una petición válida, pero ha
    denegado el acceso a la acción que se solicita.
  </div>

</div>
